import React from "react"

const Segregation = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 211.39 211.39">
    <path
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width=".75"
      d="M.38.38h210.64v210.64H.38z"
    />
    <circle
      cx="75.08"
      cy="143.25"
      r="14.46"
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width=".75"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width=".75"
      d="M75.08 99.74v29.06"
    />
    <circle
      cx="136.31"
      cy="143.25"
      r="14.46"
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width=".75"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width=".75"
      d="M136.31 99.74v29.06M60.6238 99.7339v-28.79h90.14v28.79z"
    />
    <g fill="none" stroke="#000" stroke-miterlimit="10" stroke-width=".75">
      <path d="M105.69 164.68v-2" />
      <path stroke-dasharray="4.0726 4.0726" d="M105.69 158.61v-54.98" />
      <path d="M105.69 101.59v-2" />
    </g>
    <g fill="none" stroke="#000" stroke-miterlimit="10" stroke-width=".75">
      <path d="M105.69 70.94v-2" />
      <path stroke-dasharray="4.0479 4.0479" d="M105.69 64.9V50.73" />
      <path d="M105.69 48.7v-2" />
    </g>
  </svg>
)

export default Segregation
