import React from "react"

const SM = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 211.39 211.39">
    <path
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width=".75"
      d="M.38.38h210.64v210.64H.38z"
    />
    <g fill="none" stroke="#000" stroke-miterlimit="10" stroke-width=".75">
      <path
        stroke-width=".7499925000000001"
        d="M105.693299 84.912483l31.091187 31.091187-31.091187 31.091187-31.091187-31.091187z"
      />
      <path stroke-dasharray="4" d="M58.93 67.79h93.53" />
    </g>
  </svg>
)

export default SM
