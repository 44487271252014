import React from "react"
import PropTypes from "prop-types"

import { IconButton, Box, useTheme } from "@material-ui/core"
import { X, Menu } from "react-feather"
import { createStyles, makeStyles } from "@material-ui/core/styles"

import MenuNav from "./MenuNav"

const useStyles = makeStyles(theme => {
  return createStyles({
    menuContainer: {
      backgroundColor: "black",
      transition: ".5s",
      borderTop: `10px solid ${theme.palette.primary.light}`,
      padding: `0 ${theme.spacing(4)}px`,
      [theme.breakpoints.up("sm")]: {
        padding: `0 ${theme.spacing(6)}px`,
      },
    },
    openButton: {
      padding: 0,
      backgroundColor: "transparent",
      "& svg": {
        transition: ".25s",
      },
      "&:hover": {
        backgroundColor: "transparent",
        "& svg": {
          stroke: theme.palette.primary.main,
        },
      },
    },
  })
})

const MobileMenu = ({ open, setOpen }) => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <Box ml={4}>
      <Box position="relative" zIndex={theme.zIndex.snackbar}>
        <IconButton
          classes={{ root: classes.openButton }}
          onClick={() => setOpen(!open)}
        >
          {open ? (
            <X size={32} color="white" />
          ) : (
            <Menu size={32} color="black" />
          )}
        </IconButton>
      </Box>
      <Box
        classes={{ root: classes.menuContainer }}
        height="100vh"
        width="100vw"
        maxWidth="600px"
        position="fixed"
        right={open ? 0 : "-100vw"}
        top="0"
      >
        <Box
          height="75px"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        ></Box>
        <Box overflowY="auto">
          <MenuNav mobile />
        </Box>
      </Box>
    </Box>
  )
}

MobileMenu.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
}

export default MobileMenu
