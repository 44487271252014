import React from "react"

const Globe = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 211.39 211.39">
    <path
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width=".75"
      d="M.38.38h210.64v210.64H.38z"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width=".75"
      d="M121.68 50.41c-14.1-4.97-30.1-4.57-44.65 2.39-29.11 13.93-41.41 48.81-27.49 77.92 6.96 14.55 19.17 24.91 33.27 29.88"
    />
    <g fill="none" stroke="#000" stroke-miterlimit="10" stroke-width=".75">
      <path d="M126.68 52.21c-.62-.24-1.25-.48-1.87-.7l-.67 1.89" />
      <path stroke-dasharray="3.8913 3.8913" d="M122.85 57.07l-35.6 100.91" />
      <path d="M86.6 159.82l-.67 1.89c.63.22 1.26.43 1.9.63" />
      <path
        stroke-dasharray="3.989 3.989"
        d="M91.67 163.41c12.61 3.05 26.3 1.94 38.91-4.09 29.11-13.93 41.41-48.81 27.49-77.92-6.34-13.26-17.03-23.03-29.54-28.42"
      />
    </g>
  </svg>
)

export default Globe
