import React from "react"
import { Typography, useTheme, Box } from "@material-ui/core"

const HeaderTypography = ({ text, selected }) => {
  const theme = useTheme()
  return (
    <>
      <Typography variant="body1" component="span">
        {text}
      </Typography>
      <Box
        width="100%"
        height="2px"
        style={{ backgroundColor: theme.palette.primary.light }}
      ></Box>
    </>
  )
}

export default HeaderTypography
