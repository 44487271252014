import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import { createStyles, makeStyles } from "@material-ui/core/styles"

import HeaderTypography from "./HeaderTypography"

const useStyles = makeStyles(theme => {
  return createStyles({
    menuUl: {
      display: "flex",
      listStyle: "none",
      alignItems: "center",
      "&:hover a.active div": {
        transform: "scaleX(0%)",
        "-webkit-transform": "scaleX(0)",
      },
    },
    menuUlMobile: {
      marginRight: `-${theme.spacing(6)}px`,
    },
    menuLi: {
      color: theme.palette.text.primary,
      padding: `0 ${theme.spacing(2)}px`,
      "&:last-child": {
        paddingRight: 0,
      },
      "& a": {
        display: "block",
      },
      "& div": {
        transform: "scaleX(0)",
        "-webkit-transform": "scale(0)",
        transition: ".25s",
        transformOrigin: "50% 50%",
      },
      "& a.active span": {
        fontWeight: 500,
      },
      "& a.active div": {
        transform: "scale(1)",
        "-webkit-transform": "scale(1)",
      },
      "& a:hover div": {
        transform: "scale(1) !important",
        "-webkit-transform": "scale(1) !important",
      },
    },
    menuLiMobile: {
      color: theme.palette.primary.light,
      transition: ".25s",
      "& a": {
        marginTop: "3px",
        display: "inline-block",
        width: "100%",
      },
      "& span": {
        fontSize: "1.75rem",
        padding: `${theme.spacing(2)}px 0`,
        display: "inline-block",
      },
      "& a.active span": {
        fontWeight: 500,
        color: "white",
      },
      "&:hover": {
        color: "white",
      },
    },
  })
})

const LOCAL_LINKS = [
  { link: "/custody", label: "Custody" },
  { link: "/treasury", label: "Treasury" },
  { link: "/insurance", label: "Insurance" },
  { link: "/security", label: "Security" },
  { link: "/about", label: "About" },
]

const MenuNav = ({ mobile }) => {
  const classes = useStyles()

  return (
    <nav>
      <ul className={mobile ? classes.menuUlMobile : classes.menuUl}>
        {LOCAL_LINKS.map((l, i) => (
          <li
            className={mobile ? classes.menuLiMobile : classes.menuLi}
            key={`menu-link-${i}`}
          >
            <Link to={l.link} activeClassName="active">
              <HeaderTypography text={l.label} />
            </Link>
          </li>
        ))}
        <li className={mobile ? classes.menuLiMobile : classes.menuLi}>
          <a
            href="https://blog.knoxcustody.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <HeaderTypography text="Blog" />
          </a>
        </li>
      </ul>
    </nav>
  )
}

MenuNav.propTypes = {
  mobile: PropTypes.bool,
}

export default MenuNav
