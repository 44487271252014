import React from "react"
import PropTypes from "prop-types"
import { Box } from "@material-ui/core"

const LinkedIn = props => {
  return (
    <Box>
      <svg viewBox="0 0 30 30" version="1.1" width={props.width}>
        <path
          d="M7.37704918,10.3278689 L7.37704918,30 L0.983606557,30 L0.983606557,10.3278689 L7.37704918,10.3278689 Z M22.6450036,9.3442623 C26.3626019,9.3442623 28.5603162,11.3775089 29.1546984,13.2586841 C29.7490805,15.1398592 30,16.6439883 30,19.6463568 L29.999,19.672 L30,19.6721311 L30,30 L23.6065574,30 L23.6065574,19.6721311 L23.7332987,19.6731161 C23.7145131,19.6642365 23.6956851,19.6553167 23.676815,19.6463568 C23.6787976,19.6257034 23.6797889,19.61022 23.6797889,19.5999063 C23.6797889,17.7955026 23.4452241,16.5216717 22.6450036,15.7090069 C21.4342636,14.4794387 19.010111,14.7535558 17.9805217,15.7090069 C17.2941288,16.3459744 16.8743921,17.5243043 16.7213115,19.2439969 L16.7213115,30 L10.3278689,30 L10.3278689,10.3278689 L16.7213115,10.3278689 L16.7213115,12.4436526 C18.1920407,10.3773924 20.1666047,9.3442623 22.6450036,9.3442623 Z M3.68852459,0 C5.72564047,0 7.37704918,1.65140871 7.37704918,3.68852459 C7.37704918,5.72564047 5.72564047,7.37704918 3.68852459,7.37704918 C1.65140871,7.37704918 0,5.72564047 0,3.68852459 C0,1.65140871 1.65140871,0 3.68852459,0 Z"
          fill={props.fill || "#E5CEAF"}
        ></path>
      </svg>
    </Box>
  )
}

LinkedIn.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
}

export default LinkedIn
