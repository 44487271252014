import React from "react"
import PropTypes from "prop-types"
import { Box } from "@material-ui/core"

export const HalfCircle = props => {
  return (
    <Box>
      <svg width="14px" height="14px" viewBox="0 0 14 14">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-1163.000000, -996.000000)">
            <g transform="translate(1163.000000, 996.000000)">
              <path
                d="M7,-3.8066772e-14 L7,14 C3.13400675,14 0,10.8659932 0,7 C0,3.13400675 3.13400675,-3.8066772e-14 7,-3.8066772e-14 L7,-3.8066772e-14 Z"
                id="Combined-Shape"
                fill={props.fill || "#E5CEAF"}
              ></path>
              <path
                d="M11.9989568,1 C10.3421025,1 8.84210251,1.67157288 7.75631607,2.75735931 C6.67052963,3.84314575 5.99895676,5.34314575 5.99895676,7 C5.99895676,8.65685425 6.67052963,10.1568542 7.75631607,11.2426407 C8.84210251,12.3284271 10.3421025,13 11.9989568,13 C12.3400217,13 12.6744545,12.9716146 13,12.9170117 L13,1.08298832 C12.6744545,1.0283854 12.3400217,1 11.9989568,1 Z"
                id="Combined-Shape"
                stroke={props.fill || "#E5CEAF"}
                stroke-width="2"
                transform="translate(9.499478, 7.000000) rotate(-180.000000) translate(-9.499478, -7.000000) "
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </Box>
  )
}

HalfCircle.propTypes = {
  fill: PropTypes.string,
}

export const FilledCircle = props => {
  return (
    <Box>
      <svg width="14px" height="14px" viewBox="0 0 14 14">
        <title>Oval</title>
        <desc>Created with Sketch.</desc>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g
            transform="translate(-478.000000, -996.000000)"
            fill={props.fill || "#DCBE94"}
          >
            <circle cx="485" cy="1003" r="7"></circle>
          </g>
        </g>
      </svg>
    </Box>
  )
}

FilledCircle.propTypes = {
  fill: PropTypes.string,
}

export const EmptyCircle = props => {
  return (
    <Box>
      <svg width="14px" height="14px" viewBox="0 0 14 14">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g
            transform="translate(-822.000000, -996.000000)"
            stroke={props.fill || "#DCBE94"}
            stroke-width="2"
          >
            <circle cx="829" cy="1003" r="6"></circle>
          </g>
        </g>
      </svg>
    </Box>
  )
}

EmptyCircle.propTypes = {
  fill: PropTypes.string,
}
