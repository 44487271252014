import React from "react"

const Auth = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 211.39 211.39">
    <path
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width=".75"
      d="M.38.38h210.64v210.64H.38z"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-dasharray="4"
      stroke-miterlimit="10"
      stroke-width=".75"
      d="M52.52 53.22h106.35v106.35H52.52z"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-dasharray="4"
      stroke-miterlimit="10"
      stroke-width=".75"
      d="M58.77 59.47h93.85v93.85H58.77z"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-dasharray="4"
      stroke-miterlimit="10"
      stroke-width=".75"
      d="M65.28 65.97h80.84v80.84H65.28z"
    />
    <circle
      cx="105.69"
      cy="106.39"
      r="17.5"
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width=".75"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width=".75"
      d="M123.19 106.39h63.13"
    />
  </svg>
)

export default Auth
