import React from "react"
import { Box } from "@material-ui/core"

const MultiSig = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 211.39 211.39">
    <path
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width=".75"
      d="M.38.38h210.64v210.64H.38z"
    />
    <circle
      cx="76.82"
      cy="70.38"
      r="14.46"
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width=".75"
    />
    <circle
      cx="168.04"
      cy="107.77"
      r="14.46"
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width=".75"
    />
    <circle
      cx="61.88"
      cy="139.2"
      r="14.46"
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width=".75"
    />
    <circle
      cx="43.35"
      cy="107.77"
      r="14.46"
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width=".75"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width=".75"
      d="M90.5945 122.1677v-28.79h49.1v28.79zM139.69 107.77h13.89M72.34 129.11l18.26-21.34M57.81 107.77H90.6M88.1 79.52l17.52 13.86"
    />
    <g fill="none" stroke="#000" stroke-miterlimit="10" stroke-width=".75">
      <path d="M115.15 164.68v-2" />
      <path stroke-dasharray="4.2795 4.2795" d="M115.15 158.4v-32.09" />
      <path d="M115.15 124.17v-2" />
    </g>
    <g fill="none" stroke="#000" stroke-miterlimit="10" stroke-width=".75">
      <path d="M115.15 93.32v-2" />
      <path stroke-dasharray="3.8737 3.8737" d="M115.15 87.44v-36.8" />
      <path d="M115.15 48.7v-2" />
    </g>
  </svg>
)

export default MultiSig
