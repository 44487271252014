import React from "react"
import { Typography } from "@material-ui/core"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import classNames from "classnames"

const LINK_TYPES = {
  INTERNAL_LINK: "INTERNAL_LINK",
  EXTERNAL_LINK: "EXTERNAL_LINK",
}

const useStyles = makeStyles(theme => {
  return createStyles({
    li: {
      position: "relative",
      marginLeft: ({ hasListIcon }) => (hasListIcon ? theme.spacing(4) : 0),
      marginBottom: theme.spacing(1),
      "& a": {
        transition: "0.25s",
      },
      "&::before": {},
      "& span": {
        position: "absolute",
        left: `-${theme.spacing(4)}px`,
      },
      "& a:hover": {
        color: theme.palette.primary.dark,
      },
    },
    ul: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
})

const TextList = ({ items, listIcon, ...rest }) => {
  const classes = useStyles({ hasListIcon: !!listIcon })
  return (
    <ul className={classNames(classes.ul, rest.className)} {...rest}>
      {items &&
        items.length > 0 &&
        items.map((i, j) => (
          <li key={`list-${j}`} className={classes.li}>
            {typeof i === "string" ? (
              <>
                <span>{listIcon}</span>
                <Typography variant="body1">{i}</Typography>
              </>
            ) : i.type && i.type === LINK_TYPES.INTERNAL_LINK ? (
              <Link to={i.link}>
                <span>{listIcon}</span>
                <Typography variant="body1">{i.label}</Typography>
              </Link>
            ) : (
              <a
                href={i.link}
                target={i.blankTarget && "_blank"}
                rel="noopener noreferrer"
              >
                <span>{listIcon}</span>
                <Typography variant="body1"> {i.label}</Typography>
              </a>
            )}
          </li>
        ))}
    </ul>
  )
}

const ListLink = PropTypes.shape({
  label: PropTypes.string,
  link: PropTypes.string,
  blankTarget: PropTypes.bool,
  type: PropTypes.string,
})

TextList.linkTypes = LINK_TYPES

TextList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, ListLink])),
  listIcon: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
}

export default TextList
