import * as React from "react"
import {
  ThemeProvider as MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles"

const COLORS = {
  GREY_BG: "#F7F7F7",
  GREY_MID: "#E6E6E6",
  GREY_DARK: "#787878",
  ROSE_GOLD_LIGHT: "#E5CEAF",
  ROSE_GOLD_MID: "#DCBE94",
  ROSE_GOLD_DARK: "#C8AB83",
  ROSE_GOLD_TRANSPARENT: "rgba(229, 206, 175, 0.3)",
}

export const lightTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    type: "light",
    text: {
      primary: "rgba(0, 0, 0, 1)",
      secondary: "rgba(0, 0, 0, 0.50)",
    },
    primary: {
      main: COLORS.ROSE_GOLD_MID,
      light: COLORS.ROSE_GOLD_LIGHT,
      dark: COLORS.ROSE_GOLD_DARK,
    },
    secondary: {
      main: "#000000",
    },
    background: {
      default: COLORS.GREY_BG,
      paper: "#fff",
    },
    grey: {
      50: COLORS.GREY_BG,
      100: COLORS.GREY_MID,
      200: "#C6C7C8",
      300: COLORS.GREY_DARK,
      400: "#696969",
      500: "#414140",
      600: "#3D3D3D",
      700: "#242424",
      800: "#000000",
      900: "#000000",
    },
    divider: "#E8E8E8",
  },
  shape: {
    borderRadius: 0,
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
      },
      label: {
        color: "#000000",
      },
      outlinedPrimary: {
        borderWidth: "2px",
        borderColor: COLORS.ROSE_GOLD_LIGHT,
        background: "none",
        backgroundColor: "transparent",
        "&:hover": {
          borderWidth: "2px",
          backgroundColor: COLORS.ROSE_GOLD_TRANSPARENT,
        },
      },
    },
  },
  typography: {
    fontFamily: ["Work Sans", "sans-serif"].join(","),
    fontSize: 16,
    h1: {
      fontSize: "3.75rem",
      lineHeight: "4.43rem",
      fontWeight: 500,
      fontFamily: "Work Sans",
      "&:hover": {
        cursor: "default",
      },
    },
    h2: {
      fontSize: "2rem",
      lineHeight: "2.3rem",
      fontWeight: 400,
      fontFamily: "Work Sans",
      marginBottom: "24px",
      "&:hover": {
        cursor: "default",
      },
    },
    h3: {
      fontSize: "1.3rem",
      lineHeight: "1.875rem",
      fontWeight: 400,
      fontFamily: "Work Sans",
      marginBottom: "16px",
      "&:hover": {
        cursor: "default",
      },
    },
    h4: {
      fontSize: "1rem",
      lineHeight: "1.6rem",
      fontWeight: 500,
      fontFamily: "Work Sans",
      marginBottom: "8px",
      "&:hover": {
        cursor: "default",
      },
    },
    body1: {
      fontSize: "1rem",
      lineHeight: "1.6rem",
      marginBottom: "8px",
    },
    button: {
      fontSize: "1rem",
      fontFamily: "Work Sans",
      fontWeight: 400,
      textTransform: "none",
      color: "#000000",
    },

    caption: {
      lineHeight: "0.9rem",
      fontSize: "0.8rem",
      color: "#696969",
    },
  },
})

export const ThemeProvider = ({ children }) => {
  const onlyChild = React.Children.only(children)

  if (onlyChild == null || !React.isValidElement) {
    throw new TypeError(
      "You need to pass only one valid component as the children of ThemeProvider"
    )
  }

  return <MuiThemeProvider theme={lightTheme}>{onlyChild}</MuiThemeProvider>
}
