import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Loss = ({ style }) => {
  const data = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "featureIcons/loss.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.icon.childImageSharp.fluid} />
}

export default Loss
