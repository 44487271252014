import React from "react"
import Logo from "../Logo"
import { Link } from "gatsby"
import { Box, Grid, Container, Typography } from "@material-ui/core"
import TextList from "../TextList"
import { LinkedIn, Twitter } from "../Icons"

import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles"
import SocialIconWrapper from "../SocialIconWrapper"

import { KNOX_PHONE_NUMBER, KNOX_CONTACT_EMAIL } from "../../content"

const LIST_1 = [
  {
    link: "/custody",
    label: "Custody",
    type: TextList.linkTypes.INTERNAL_LINK,
  },
  {
    link: "/security",
    label: "Security",
    type: TextList.linkTypes.INTERNAL_LINK,
  },
  {
    link: "/insurance",
    label: "Insurance",
    type: TextList.linkTypes.INTERNAL_LINK,
  },
  {
    link: "/about",
    label: "About",
    type: TextList.linkTypes.INTERNAL_LINK,
  },
]

const LIST_2 = [
  {
    link: "/contact",
    label: "Contact",
    type: TextList.linkTypes.INTERNAL_LINK,
  },
  // {
  //   link: "/about",
  //   label: "Careers",
  //   type: TextList.linkTypes.INTERNAL_LINK,
  // },
  // {
  //   link: "/support",
  //   label: "Support",
  //   type: TextList.linkTypes.INTERNAL_LINK,
  // },
  {
    link: "https://blog.knoxcustody.com/",
    label: "Blog",
    blankTarget: true,
    type: TextList.linkTypes.EXTERNAL_LINK,
  },
]

const useStyles = makeStyles(theme => {
  return createStyles({
    footer: {
      backgroundColor: "#000",
      width: "100%",
      borderTop: `10px solid ${theme.palette.primary.light}`,
    },
    container: {
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(6),
      },
    },
    list: {
      color: "white",
    },
    smallText: {
      fontSize: "0.8rem",
      lineHeight: "1.1rem",
      opacity: 0.8,
    },
    link: {
      transition: ".25s",
      color: theme.palette.primary.light,
      display: "block",
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
  })
})

const Footer = () => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <footer className={classes.footer}>
      <Container
        classes={{ root: classes.container }}
        maxWidth="md"
        disableGutters
      >
        <Grid container spacing={4}>
          <Grid item xs={6} sm={4}>
            <Box height="25px" mb={2}>
              <Link to="/">
                <Logo color={theme.palette.primary.light} />
              </Link>
            </Box>
            <TextList items={LIST_1} className={classes.list} />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box height="25px" display="flex" alignItems="flex-end" mb={2}>
              <Typography
                style={{ marginBottom: 0, color: theme.palette.primary.light }}
              >
                Company
              </Typography>
            </Box>
            <TextList items={LIST_2} className={classes.list} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box display="flex" alignItems="flex-end" mb={2}>
              <Box mr={2}>
                <SocialIconWrapper type="LINKED_IN">
                  <LinkedIn width="24px" fill={theme.palette.primary.main} />
                </SocialIconWrapper>
              </Box>
              <Box>
                <SocialIconWrapper type="TWITTER">
                  <Twitter width="26px" fill={theme.palette.primary.main} />
                </SocialIconWrapper>
              </Box>
            </Box>
            <Box mb={2}>
              <Typography variant="body1">
                <a
                  href={`mailto:${KNOX_CONTACT_EMAIL}`}
                  className={classes.link}
                >
                  {KNOX_CONTACT_EMAIL}
                </a>
              </Typography>
            </Box>
            <Box>
              <Link to="/privacy">
                <Typography style={{ color: "white" }}>
                  Read Privacy Policy
                </Typography>
              </Link>
              <Typography color="primary">© 2023 Knox</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              classes={{ root: classes.smallText }}
              variant="body1"
              color="primary"
            >
              Custody services are offered through KNØX Industries Inc., a
              Canada-incorporated company. KNØX Industries Inc. is not engaged
              in the business of the offer, sale or trading of securities and
              does not provide legal, tax, or investment advice.
            </Typography>
            <Typography
              classes={{ root: classes.smallText }}
              variant="body1"
              color="primary"
            >
              Holdings of Bitcoin and other digital assets are speculative and
              involve a substantial degree of risk that is only for investors
              with a high risk tolerance.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}

export default Footer
