import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { Container } from "@material-ui/core"

import Header from "./Header"
import Footer from "./Footer"

import "./layout.scss"

import { createStyles, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => {
  return createStyles({
    containerRoot: {
      minHeight: "100vh",
      padding: `${theme.spacing(4)}px 0`,
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
      },
    },
  })
})

const LayoutContent = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const classes = useStyles()
  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Container
        maxWidth="md"
        disableGutters
        classes={{ root: classes.containerRoot }}
      >
        <main className="wrap">{children}</main>
      </Container>
      <Footer />
    </>
  )
}

LayoutContent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutContent
