import LinkedIn from "./LinkedIn"
import Twitter from "./Twitter"
import Paperclip from "./Paperclip"
import { HalfCircle, FilledCircle, EmptyCircle } from "./Circles"
import Auth from "./auth"
import Backups from "./backups"
import Globe from "./globe"
import Graph from "./graph"
import Isolation from "./isolation"
import Multisig from "./multisig"
import Recovery from "./recovery"
import Segegation from "./segregation"
import SM from "./sm"
import LifeCycle from "./LifeCycle"
import Collusion from "./Collusion"
import Theft from "./Theft"
import Loss from "./Loss"
import AirGap from "./AirGap"
import GitHub from "./GitHub"

export {
  LinkedIn,
  Twitter,
  Paperclip,
  HalfCircle,
  FilledCircle,
  EmptyCircle,
  Auth,
  Backups,
  Globe,
  Graph,
  Isolation,
  Multisig,
  Recovery,
  Segegation,
  SM,
  Collusion,
  LifeCycle,
  Theft,
  Loss,
  AirGap,
  GitHub,
}

export default {
  LinkedIn,
  Twitter,
  Paperclip,
  HalfCircle,
  FilledCircle,
  EmptyCircle,
  Auth,
  Backups,
  Globe,
  Graph,
  Isolation,
  Multisig,
  Recovery,
  Segegation,
  SM,
  Collusion,
  LifeCycle,
  Theft,
  Loss,
  AirGap,
  GitHub,
}

export const ICON_NAMES = {
  LinkedIn: "LinkedIn",
  Twitter: "Twitter",
  Paperclip: "Paperclip",
  HalfCircle: "HalfCircle",
  FilledCircle: "FilledCircle",
  EmptyCircle: "EmptyCircle",
  Auth: "Auth",
  backups: "backups",
  Globe: "Globe",
  Graph: "Graph",
  Isolation: "Isolation",
  Multisig: "Multisig",
  Recovery: "Recovery",
  Segegation: "Segegation",
  SM: "SM",
  Collusion: "Collusion",
  LifeCycle: "LifeCycle",
  Theft: "Theft",
  Loss: "Loss",
  AirGap: "AirGap",
  GitHub: "GitHub",
}
