import React from "react"

const Recovery = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 211.39 211.39">
    <path
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width="2"
      d="M.38.38h210.64v210.64H.38z"
    />
    <g fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="2">
      <path
        stroke-dasharray="6"
        stroke-width="2"
        d="M33.776246 105.691352l48.7899-48.7899 48.7899 48.7899-48.7899 48.7899z"
      />
      <path
        stroke-dasharray="6"
        stroke-width="2"
        d="M56.854414 105.687484l48.7899-48.7899 48.7899 48.7899-48.7899 48.7899z"
      />
      <path
        stroke-width="2"
        d="M79.925511 105.690687l48.7899-48.7899 48.7899 48.7899-48.7899 48.7899z"
      />
    </g>
  </svg>
)

export default Recovery
