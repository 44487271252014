import React from "react"
import PropTypes from "prop-types"
import { Box } from "@material-ui/core"

const Twitter = props => {
  return (
    <Box>
      <svg viewBox="0 0 32 26" width={props.width}>
        <path
          d="M10.06336,26 C22.13888,26 28.74368,15.9962435 28.74368,7.32103089 C28.74368,7.03689144 28.74368,6.7540319 28.72448,6.47245226 C30.0093841,5.54312537 31.118535,4.39244832 32,3.07429802 C30.8017652,3.6052059 29.5306615,3.95337512 28.22912,4.10718332 C29.5996723,3.28673969 30.6254381,1.99632412 31.11552,0.476085929 C29.8267616,1.24077343 28.416827,1.77968704 26.94656,2.06957069 C24.9114659,-0.0942481468 21.6777208,-0.623850414 19.0586231,0.777734421 C16.4395254,2.17931926 15.086432,5.16352386 15.75808,8.05697767 C10.4792265,7.79235621 5.560929,5.2991909 2.2272,1.19795372 C0.484637128,4.19760203 1.37470449,8.03503939 4.25984,9.96147993 C3.21502874,9.93051583 2.1929976,9.64868742 1.28,9.13977936 C1.28,9.16665741 1.28,9.19481538 1.28,9.22297334 C1.28085521,12.3479863 3.4838419,15.0395577 6.5472,15.6583479 C5.58063308,15.9219321 4.56650315,15.9604628 3.58272,15.7709798 C4.44281335,18.4452476 6.90761544,20.2772572 9.71648,20.3300101 C7.39166085,22.1569908 4.51975617,23.1487857 1.56288,23.1458064 C1.04051657,23.1448037 0.518661204,23.1131784 0,23.0510933 C3.00241402,24.9777079 6.49588915,25.9996377 10.06336,25.9948804"
          id="Path"
          fill={props.fill || "#E5CEAF"}
        ></path>
      </svg>
    </Box>
  )
}

Twitter.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
}

export default Twitter
