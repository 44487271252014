import React from "react"
import { Link } from "gatsby"
import { ArrowRight, Download } from "react-feather"
import { Button } from "@material-ui/core"
import { Paperclip } from "./Icons"

export const CTA_TYPES = {
  INTERNAL_LINK: "INTERNAL_LINK",
  EXTERNAL_LINK: "EXTERNAL_LINK",
  DOWNLOAD_LINK: "DOWNLOAD_LINK",
}

export const CTA_NAMES = {
  scheduleDemo: "scheduleDemo",
  requestInvite: "requestInvite",
  contact: "contact",
  scheduleCall: "scheduleCall",
  custody: "custody",
  security: "security",
  insurance: "insurance",
  moreOnBlog: "moreOnBlog",
  currentOpenings: "currentOpenings",
  downloadPressKit: "downloadPressKit",
  pricing: "pricing",
  riskRegistry: "riskRegistry",
  goHome: "goHome",
  soc2: "soc2"
}

const CTAS = {
  [CTA_NAMES.scheduleDemo]: {
    link: "/contact",
    label: "Schedule a demo",
    type: CTA_TYPES.INTERNAL_LINK,
  },
  [CTA_NAMES.requestInvite]: {
    link: "/contact",
    label: "Request an invite",
    type: CTA_TYPES.INTERNAL_LINK,
  },
  [CTA_NAMES.contact]: {
    link: "/contact",
    label: "Contact Knox",
    type: CTA_TYPES.INTERNAL_LINK,
  },
  [CTA_NAMES.custody]: {
    link: "/custody",
    label: "Custody Solutions",
    type: CTA_TYPES.INTERNAL_LINK,
  },
  [CTA_NAMES.security]: {
    link: "/security",
    label: "Security",
    type: CTA_TYPES.INTERNAL_LINK,
  },
  [CTA_NAMES.insurance]: {
    link: "/insurance",
    label: "Insurance",
    type: CTA_TYPES.INTERNAL_LINK,
  },
  [CTA_NAMES.pricing]: {
    link: "/custody#pricing",
    label: "Pricing",
    type: CTA_TYPES.INTERNAL_LINK,
  },
  [CTA_NAMES.moreOnBlog]: {
    link: "https://blog.knoxcustody.com/",
    label: "Read More on our Blog",
    type: CTA_TYPES.EXTERNAL_LINK,
  },
  // [CTA_NAMES.currentOpenings]: {
  //   link: "https://www.mempoolreview.com/",
  //   label: "View Current Openings",
  //   type: CTA_TYPES.EXTERNAL_LINK,
  // },
  // [CTA_NAMES.riskRegistry]: {
  //   link: "https://www.mempoolreview.com/",
  //   label: "Read Risk Registry",
  //   type: CTA_TYPES.EXTERNAL_LINK,
  // },
  [CTA_NAMES.downloadPressKit]: {
    link:
      "https://drive.google.com/drive/folders/19F8nJwQAAhCBY43yNwsCzC5Jj0AsFKGY",
    label: "Full Press Kit",
    type: CTA_TYPES.EXTERNAL_LINK,
  },
  [CTA_NAMES.scheduleCall]: {
    link: "/contact",
    label: "Schedule a call with us",
    type: CTA_TYPES.INTERNAL_LINK,
  },
  [CTA_NAMES.goHome]: {
    link: "/",
    label: "Go home",
    type: CTA_TYPES.INTERNAL_LINK,
  },
  [CTA_NAMES.soc2]: {
    link: "https://blog.knoxcustody.com/press-release-knox-custody-completes-soc-2-type-ii-audit/",
    label: "Read the announcement",
    type: CTA_TYPES.EXTERNAL_LINK,
  },
}

export const CTA = ({ name, ...rest }) => {
  const content = CTAS[name]
  if (
    content &&
    content.type &&
    Object.keys(CTA_TYPES).indexOf(content.type > -1)
  ) {
    if (content.type === CTA_TYPES.INTERNAL_LINK) {
      return (
        <Button
          component={Link}
          to={content.link}
          color="primary"
          variant="outlined"
          endIcon={<ArrowRight size={16} />}
          {...rest}
        >
          {content.label}
        </Button>
      )
    }
    if (content.type === CTA_TYPES.EXTERNAL_LINK) {
      return (
        <Button
          color="primary"
          variant="outlined"
          component="a"
          href={content.link}
          target="_blank"
          rel="noopener noreferrer"
          startIcon={<Paperclip fill="#000" />}
          {...rest}
        >
          {content.label}
        </Button>
      )
    }
    if (content.type === CTA_TYPES.DOWNLOAD_LINK) {
      return (
        <Button
          color="primary"
          variant="outlined"
          component="a"
          download
          href={content.link}
          target="_blank"
          rel="noopener noreferrer"
          startIcon={<Download size={16} />}
          {...rest}
        >
          {content.label}
        </Button>
      )
    }
  }
  return null
}
