import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "../styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import LayoutContent from "./LayoutContent"

import "./layout.scss"

const Layout = ({ children }) => {
  return (
    <ThemeProvider>
      <>
        <CssBaseline />
        <LayoutContent children={children} />
      </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
