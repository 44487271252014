import React from "react"

import { createStyles, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => {
  return createStyles({
    link: {
      "& path": {
        transition: "0.25s",
      },
      "&:hover path": {
        fill: ({ hoverColor }) => hoverColor || theme.palette.primary.dark,
      },
    },
  })
})

const SOCIAL_MEDIA_LINKS = {
  LINKED_IN: {
    url: "https://www.linkedin.com/company/11803258",
    name: "",
  },
  TWITTER: {
    url: "https://twitter.com/knoxcustody",
    name: "",
  },
}

const SocialIconWrapper = ({ children, type, hoverColor, url, name }) => {
  const classes = useStyles({ hoverColor })

  let val
  if (url) {
    val = {
      url,
      name,
    }
  }
  if (!url && type) {
    val = SOCIAL_MEDIA_LINKS[type]
  }
  if (val) {
    return (
      <a
        className={classes.link}
        href={val.url}
        rel="noopener noreferrer"
        target="_blank"
      >
        {children}
      </a>
    )
  }
  return null
}

export default SocialIconWrapper
