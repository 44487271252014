import React from "react"
import PropTypes from "prop-types"
import { Box } from "@material-ui/core"

const Paperclip = props => {
  return (
    <svg viewBox="0 0 21 10" width="20px">
      <path
        d="M9.45629652,0 L9.45629652,1.88888889 L5.87016009,1.88888889 C4.36025476,1.88888889 3.36528637,2.28267714 2.75780284,2.96383797 C2.2865795,3.49221259 2.04559193,4.21101842 2.03354341,4.95943511 L2.03354896,5.00426883 C2.04573825,5.76129703 2.2895443,6.48849844 2.76650228,7.02330321 C3.34290989,7.66961916 4.26366998,8.06017387 5.6340985,8.10646853 L5.91421099,8.11111111 L9.53289474,8.11111111 L9.53289474,10 L5.91421099,10 L5.83344536,9.99969056 C3.7670444,9.98381207 2.20775993,9.35731489 1.20101007,8.22846359 C0.440299579,7.37549202 0.0567723374,6.30032622 0.0058639075,5.19897743 L0.001,5.018 L0,5.01857922 L0.001,4.993 L0,4.959 L0,4.94444444 L0,4.94444444 L0.001,4.944 L0.00582074387,4.76593893 C0.0563546819,3.67277394 0.437065296,2.60552119 1.19231063,1.75867759 C2.19180348,0.637963461 3.73962906,0.0160689715 5.79002052,0.000307165695 L9.45629652,0 Z M14.6447368,4.1025641 L14.6447368,5.8974359 L6.21710526,5.8974359 L6.21710526,4.1025641 L14.6447368,4.1025641 Z M20.9941361,5.19838464 C20.9432277,6.29986942 20.5597004,7.37516796 19.7989899,8.22824486 C18.7922401,9.35723553 17.2329556,9.98381008 15.1665546,9.99969052 L15.1665546,9.99969052 L15.085789,10 L11.4671053,10 L11.4671053,8.11087789 L15.085789,8.11087789 L15.3659015,8.10623474 C16.73633,8.05993435 17.6570901,7.66933142 18.2334977,7.02293568 C18.7104557,6.48806487 18.9542617,5.76077366 18.966451,5.003652 L18.966451,5.003652 L18.9669322,4.94382022 C18.9525142,4.20855751 18.713029,3.50583779 18.25505,2.98604384 C17.6503976,2.29977957 16.6600663,1.90304095 15.157198,1.90304095 L15.157198,1.90304095 L11.5877748,1.90304095 L11.5877748,0 L15.236964,0.000309467063 C17.2777996,0.0161893645 18.8184115,0.642743256 19.8132462,1.77185407 C20.5649717,2.62504245 20.943908,3.70029136 20.9942064,4.80164663 L20.9942064,4.80164663 L20.998,4.943 L21,4.94382022 L20.999,4.996 L20.999,5.003 L21,5.05617978 L20.998,5.056 Z"
        id="Combined-Shape"
        fill={props.fill || "#E5CEAF"}
      ></path>
    </svg>
  )
}

Paperclip.propTypes = {
  fill: PropTypes.string,
}

export default Paperclip
