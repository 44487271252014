import React from "react"

const Isolation = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 211.39 211.39">
    <path
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width=".75"
      d="M.38.38h210.64v210.64H.38z"
    />
    <g fill="none" stroke="#000" stroke-miterlimit="10" stroke-width=".75">
      <circle cx="105.69" cy="61.7" r="13.77" />
      <circle cx="105.69" cy="105.69" r="13.77" />
      <circle cx="149.35" cy="61.7" r="13.77" />
      <g>
        <circle cx="62.04" cy="61.7" r="13.77" />
        <circle cx="62.04" cy="105.69" r="13.77" />
        <circle cx="62.04" cy="149.68" r="13.77" />
      </g>
    </g>
  </svg>
)

export default Isolation
