import React, { useState } from "react"
import { Link } from "gatsby"
import Headroom from "react-headroom"
import classnames from "classnames"
import { CTA, CTA_NAMES } from "../CTAs"
import Logo from "../Logo"
import {
  Container,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"

import { createStyles, makeStyles } from "@material-ui/core/styles"

import MenuNav from "./MenuNav"
import MobileMenu from "./MobileMenu"

const useStyles = makeStyles(theme => {
  return createStyles({
    logoH1: {
      width: "80px",
      "& a": {
        display: "flex",
      },
    },
    header: {
      backgroundColor: "#FFFFFF",
      transition: ".25s",
      width: "100%",
      height: "85px",
      borderTop: `10px solid ${theme.palette.primary.light}`,
    },
    openMenuButton: {
      "& span": {
        color: "white",
        transition: ".25s",
      },
      "&:hover span": {
        color: theme.palette.primary.light,
      },
    },
    menuContainer: {
      backgroundColor: "black",
      transition: ".5s",
      borderTop: `10px solid ${theme.palette.primary.light}`,
    },
    headroomOpenMenu: {
      "& > div": {
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        width: "100%",
        position: "fixed",
        transform: "translateY(0%)",
      },
    },
    cta: {
      [theme.breakpoints.down(432)]: {
        display: "none",
      },
    },
    container: {
      padding: `0 ${theme.spacing(4)}px`,
      [theme.breakpoints.up("sm")]: {
        padding: `0 ${theme.spacing(6)}px`,
      },
      [theme.breakpoints.up("md")]: {
        padding: `0 ${theme.spacing(6)}px`,
      },
    },
  })
})

const Header = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const [open, setOpen] = useState(false)
  return (
    <>
      <Headroom
        disable={open}
        disableInlineStyles={open}
        className={open ? classes.headroomOpenMenu : ""}
        wrapperStyle={{ height: "100px" }}
      >
        <header className={classnames({ [classes.header]: true })}>
          <Container
            maxWidth="md"
            disableGutters
            classes={{ root: classes.container }}
          >
            <Box
              display="flex"
              alignItems="center"
              height="75px"
              justifyContent="space-between"
            >
              <Typography
                variant="h1"
                display="block"
                classes={{ root: classes.logoH1 }}
              >
                <Link to="/">
                  <Logo />
                </Link>
              </Typography>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                {!isSmallScreen && <MenuNav />}
                <Box
                  ml={4}
                  position="relative"
                  zIndex={theme.zIndex.snackbar}
                  classes={{ root: classes.cta }}
                >
                  <CTA
                    className={
                      isSmallScreen && open ? classes.openMenuButton : ""
                    }
                    name={CTA_NAMES.scheduleDemo}
                  />
                </Box>
                {isSmallScreen && <MobileMenu open={open} setOpen={setOpen} />}
              </Box>
            </Box>
          </Container>
        </header>
      </Headroom>
    </>
  )
}

export default Header
