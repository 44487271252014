import React from "react"
import PropTypes from "prop-types"
import { Box } from "@material-ui/core"

const GitHub = props => {
  return (
    <Box>
      <svg viewBox="0 0 67 66" width={props.width}>
        <g
          id="icon/twitter-copy"
          transform="translate(-7.000000, 0.000000)"
          fill="#E5CEAF"
        >
          <path
            d="M40.7958788,16.3999508 L40.1297771,16.4022192 C38.5230214,16.4207628 36.1085638,16.5606113 32.6727356,17.6375526 C30.5178244,15.09494 24.6256348,13.3455051 23.5908489,14.2342531 C22.5560629,15.1230012 22.1231604,17.6375526 23.2715943,22.9923996 L22.7652565,23.4508059 C20.9665941,25.1185788 19.8199265,26.7649286 19.8199265,32.4201646 C19.8199265,38.589513 21.4139043,42.6739521 25.9773401,45.1501834 C29.0196306,46.8010044 31.9697328,47.739948 34.8276467,47.9670143 C33.3910393,49.7019905 32.6727356,51.2538907 32.6727356,52.6227148 L32.1063889,52.7701083 C30.2234355,53.237266 29.3579482,53.2099325 28.7299069,53.2256493 C28.0420521,53.2428629 25.1990566,52.3378874 23.5908489,50.1524508 C21.9826411,47.9670143 19.55828,45.9706976 18.1857836,45.9706976 C16.8132872,45.9706976 16.5,46.5206142 16.5,46.9663313 C16.5,47.4120483 19.8541241,48.7074279 20.4650916,50.4714759 C21.0760591,52.235524 22.1963191,54.5221764 24.5885482,55.6683716 C26.9807773,56.8145667 30.1730937,57.8487588 32.6727356,57.0321464 L32.6727356,65.0557241 C32.6727356,65.4380726 32.4941914,65.7352276 32.137103,65.9471889 C17.6811855,62.231842 7,49.1132833 7,33.5 C7,14.9984609 21.9984609,-7.10542736e-15 40.5,-7.10542736e-15 C59.0015391,-7.10542736e-15 74,14.9984609 74,33.5 C74,49.0137759 63.4545284,62.0645249 49.140818,65.8750144 C48.8380215,65.6799202 48.6683327,65.4175534 48.6306418,65.088503 L48.6225667,64.9434004 L48.6225667,57.0283428 C48.6225667,53.6196661 48.3828955,49.5588522 46.4678461,47.9640119 L46.9127397,47.9605508 C49.3803255,47.91958 51.0078398,47.4858864 55.3173704,45.1474301 C59.8804028,42.6714176 61.4742398,38.5873395 61.4742398,32.4185365 C61.4742398,26.2497334 60.1097311,24.8514145 58.0228771,22.9916048 C59.1712095,17.6372311 58.7383452,15.1229019 57.7036507,14.2342324 C56.6689562,13.3455629 50.7772874,15.0948432 48.6225667,17.6372311 C44.9122002,16.4742373 42.3930552,16.4041719 40.7958788,16.3999508 Z"
            fill={props.fill || "#E5CEAF"}
          ></path>
        </g>
      </svg>
    </Box>
  )
}

GitHub.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
}

export default GitHub
