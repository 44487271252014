import React from "react"

const Graph = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 211.39 211.39">
    <path
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width=".75"
      d="M.38.38h210.64v210.64H.38z"
    />
    <g fill="none" stroke="#000" stroke-miterlimit="10" stroke-width=".75">
      <path d="M119.4645 159.5285h-28.79v-102.5h28.79zM90.6716 108.2796h-28.79v-51.25h28.79zM148.2574 144.6366h-28.79v-87.61h28.79z" />
    </g>
  </svg>
)

export default Graph
